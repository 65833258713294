export default {
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    apiKey: process.env.REACT_APP_API_KEY,
    siteUrl: process.env.REACT_APP_BASE_URL,
    basicToken: process.env.REACT_APP_BASIC_TOKEN,
    Api: {
      baseUrl: process.env.REACT_APP_API_BASE_URL,
    },
    FedidCodeVerifier: {
      cacheKey: "fedidCodeVerifier",
    },
    FedidLogin: {
      cacheKey: "fedidLoginAccessToken",
    },
    FedidUrl: {
      cacheKey: "fedidUrl",
    },
  };
  