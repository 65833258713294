/* eslint-disable jsx-a11y/alt-text */
import "./App.css";
import { useEffect, useState, useRef } from "react";
import config from "./services/config";

function Home() {
  const [accessToken, setAccessToken] = useState("");
  const [barcode, setBarcode] = useState();
  const [productData, setProductData] = useState();
  const [responseStatus, setResponseStatus] = useState(true);

  const product = useRef(null);

  const conditions = {
    1: "İyi değil",
    2: "Fena değil",
    3: "İyi durumda",
    4: "Yeni gibi",
  };
  const limit = 30;

  const secondLife = "İkinci Şans";
  const secondLifeDesc =
    "Tekstil ürününden ekipmana bir çok ürünü kusuruna göre yeniden fiyatlandırıyor ve kullanımınıza sunuyoruz.";
  const buyBack = "BuyBack";
  const buyBackDesc =
    "Mağazalarımızdan satın aldığınız Decathlon ürünlerini, kondisyonunu ve işlevselliğini değerlendirerek sizlerden geri satın alıyoruz.";

  useEffect(() => {
    let myHeaders = new Headers();

    myHeaders.append("Authorization", `Basic ${config.basicToken}`);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "client_credentials");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(
      "https://idpdecathlon.oxylane.com/as/token.oauth2",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setAccessToken(result.access_token);
      })
      .catch((error) => console.log("error", error));

    const pathSegments = window.location.pathname.split("/");
    const paramToCheck = pathSegments[pathSegments.length - 1];

    if (paramToCheck) {
      setBarcode(paramToCheck);
      SearchProduct(paramToCheck);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    product?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [productData]);

  const SearchProduct = (param) => {
    fetch(`${config.baseUrl}/v1/products/customer/explanation?code=${param}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "x-api-key": config.apiKey,
        "Content-Type": "application/json",
      },
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((res) => {
        setProductData(res.data);
        res.status === "200"
          ? setResponseStatus(true)
          : setResponseStatus(false);
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="App font-sans">
      <div className="w-full flex justify-center">
        <div className="bannerContainer flex relative w-[70%] items-center md:w-[90%]">
          <img
            src="https://contents.mediadecathlon.com/b97795/k$82575b793147414ccea4e49aab96dbf7/111.png"
            className="m-auto md:hidden"
          />
          <img
            src="https://contents.mediadecathlon.com/s1041317/k$3e6ab4137cdcdca6ab7068abba41d337/as.png"
            className="hidden md:block md:m-auto md:w-full"
          />
          <img
            src="https://contents.mediadecathlon.com/s1041280/k$0080e1693da2fd4c4490d0f4f44a69dc/Group%20194.png"
            className="absolute left-0 w-[25%]"
          />
          <img
            src="https://contents.mediadecathlon.com/s1041316/k$e871f9b355706922ad5ce4fce9dc55c0/Group%20193.png"
            className="absolute bottom-0 right-[10%] heroBannerMobileLetsGetMoving"
          />
        </div>
      </div>

      {/* ///////////////////////////////////// */}
      <div className="flex items-center justify-center my-5 ">
        <div className="w-[70%] flex md:w-[90%] flex-col">
          <div className="my-5 flex flex-col items-center">
            <span className="font-bold text-3xl">
              Ürün Referans Kodunu kullanarak ürüne ulaşabilirsiniz
            </span>
            <span className="my-2">
              Ürün referans kodunuzu ürünün fişi üzerinde bulunan 30 haneli
              “777” veya “666” ile başlayan koddur. <br /> (Altı çizili kısım
              örnek koddur)
            </span>
            <img
              src="https://contents.mediadecathlon.com/s1041302/k$beeba5a140e72a7b390b9adc4721f604/dGroup%20334.png"
              className="w-[40%] md:w-full"
            />
          </div>
          <div className="relative w-full flex">
            <input
              type="number"
              className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-3 p-2.5"
              placeholder="30 haneli kodu giriniz"
              required
              value={barcode}
              onChange={(e) => {
                setBarcode(e.target.value.slice(0, limit));
                setResponseStatus(true);
              }}
            />
            <button
              className="absolute right-0 h-full w-[3rem] flex justify-center items-center"
              onClick={() => SearchProduct(barcode)}
            >
              <svg
                className="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </button>
          </div>
          {!responseStatus && (
            <div className="relative w-full flex gap-1 mt-2">
              <svg
                width="20"
                height="20"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="h-full self-center "
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.33325 8.13298C1.33325 11.815 4.31792 14.7996 7.99992 14.7996C11.6819 14.7996 14.6666 11.815 14.6666 8.13298C14.6666 4.45098 11.6819 1.46631 7.99992 1.46631C4.31792 1.46631 1.33325 4.45098 1.33325 8.13298ZM13.3333 8.13298C13.3333 11.0785 10.9454 13.4663 7.99992 13.4663C5.0544 13.4663 2.66659 11.0785 2.66659 8.13298C2.66659 5.18746 5.0544 2.79964 7.99992 2.79964C10.9454 2.79964 13.3333 5.18746 13.3333 8.13298ZM8.66659 10.133V11.4663H7.33325V10.133H8.66659ZM8.66659 8.79964V4.79964H7.33325V8.79964H8.66659Z"
                  fill="#E3262F"
                />
              </svg>
              Girdiğiniz kod hatalı!
            </div>
          )}

          {productData && (
            <div
              className="flex w-full mt-5 rounded-lg md:flex-col md:rounded-none md:border"
              style={{ background: "#EFF1F3" }}
              ref={product}
            >
              <div className="w-[30%] relative md:w-full">
                <img
                  src={productData?.imageUrl}
                  className=" p-1 rounded-lg  max-w-[100%] md:p-0 md:rounded-none md:w-full md:max-w-full"
                />
                <span
                  className="absolute  bottom-5 left-5 py-1 px-2 rounded-lg"
                  style={{ color: "#687787", background: "#D9DDE1" }}
                >
                  {productData?.referenceCode}
                </span>
              </div>
              <div className="w-[70%] flex flex-col justify-between text-left  px-3 py-5 relative md:px-4 md:w-full md:gap-3">
                <div className="flex flex-col gap-2.5">
                  <span className="font-bold text-2xl">
                    {productData?.brand}
                  </span>
                  <span style={{ color: "#4E5D6B" }}>{productData?.title}</span>
                  <span>
                    <span className="font-bold"> Ürün Durumu: </span>
                    {conditions[productData?.condition] || "Bulunamadı"}
                  </span>
                  <span>
                    <span className="font-bold">Ürün Açıklaması:</span>{" "}
                    {productData?.description || "Bulunamadı"}
                  </span>
                </div>
                <span style={{ color: "#007DBC" }} className="flex font-bold">
                  <svg
                    width="28"
                    height="25"
                    viewBox="0 0 28 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-3"
                  >
                    <path
                      d="M25.9552 13.4683V22.8028C25.9552 23.1565 25.8147 23.4956 25.5646 23.7457C25.3145 23.9958 24.9753 24.1363 24.6217 24.1363H3.28565C2.93199 24.1363 2.59281 23.9958 2.34273 23.7457C2.09265 23.4956 1.95215 23.1565 1.95215 22.8028V13.4683H0.618652V10.8013L1.95215 4.1338H25.9552L27.2887 10.8013V13.4683H25.9552ZM4.61915 13.4683V21.4693H23.2882V13.4683H4.61915ZM3.33899 10.8013H24.5683L23.7682 6.8008H4.13909L3.33899 10.8013ZM5.95265 14.8018H16.6207V18.8023H5.95265V14.8018ZM1.95215 0.133301H25.9552V2.8003H1.95215V0.133301Z"
                      fill="#007DBC"
                    />
                  </svg>
                  {productData?.store}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* ///////////////////// */}

      <div className="w-full desc flex justify-center my-5">
        <div className="w-[70%] flex flex-col md:w-[90%]">
          <span className="font-bold text-left mt-5 text-xl">
            D-Care Nedir ?
          </span>
          <span className="text-left ">
            Doğayı önemsiyoruz ve daha az karbon salınımı için kusurlu ve
            kullanılmış ürünleri İkinci şans veya Buyback programlarımızla
            mağazalarımızda satışınıza sunuyoruz. Satın aldığınız ürünlerin
            durumlarını D-Care web sitesinden faturanızda bulunan ürün numarası
            ile kontrol edebilirsiniz.
          </span>
        </div>
      </div>

      {/* ///////////////////// */}

      <div className="w-full desc flex justify-center">
        <div className="secondLife w-[70%] flex md:w-[90%] relative">
          <div
            className="w-full text-left px-[3rem] pt-[5rem] relative md:hidden"
            style={{ background: "#C8DF4B" }}
          >
            <span className="text-white text-2xl md:ml-3 md:mb-3 font-sans">
              {secondLifeDesc}
            </span>
            <span
              className="w-[250px] text-center text-3xl absolute font-bold left-0 py-3 px-10 text-white bottom-10 md:relative md:w-[15rem] md:ml-3 md:px-3 md:text-center md:font-bold md:text-white shadow shadow-black"
              style={{ background: "#007DBC" }}
            >
              {secondLife}
            </span>
          </div>

          <div className="hidden md:absolute md:flex md:flex-col md:w-full md:h-full md:justify-end">
            <span
              className="md:p-3 md:relative md:w-[9rem] md:ml-3 md:px-2 md:text-center md:text-white md:font-bold md:text-xl"
              style={{ background: "#007DBC" }}
            >
              {secondLife}
            </span>
            <span
              className="md:text-white md:text-lg md:font-bold md:mx-3 md:text-left md:mb-3 md:my-2"
              style={{ fontFamily: "Roboto" }}
            >
              {secondLifeDesc}
            </span>
          </div>
          <img
            src="https://contents.mediadecathlon.com/b97794/k$a48c5701b7168f9118e6deefa8d92de1/444.png"
            className="md:w-full"
          />
        </div>
      </div>

      {/* ///////////////////// */}

      <div className="w-full desc flex justify-center my-5">
        <div className="buyBack w-[70%] flex md:w-[90%] md:flex-row-reverse relative">
          <img
            src="https://contents.mediadecathlon.com/b97793/k$6763807c66a5a50bf7e5cbdf815f49df/555.png"
            className="md:w-full"
          />
          <div
            className="w-full text-left px-[3rem] pt-[5rem] relative md:flex-col-reverse md:w-0 md:pl-0 md:pt-0 md:hidden"
            style={{ background: "#0082C3" }}
          >
            <span className="text-white text-2xl md:ml-3 md:mb-3 md:text-black">
              {buyBackDesc}
            </span>
            <span
              className="w-[250px] text-center text-3xl absolute right-0 py-3 px-10 text-white font-bold bottom-10 md:relative md:w-[15rem] md:ml-3 md:px-3 md:text-center md:font-bold md:text-white shadow shadow-black"
              style={{ background: "#C8DF4B" }}
            >
              {buyBack}
            </span>
          </div>
          <div className="hidden md:absolute md:flex md:flex-col md:w-full md:h-full md:justify-end">
            <span
              className="md:p-3 md:relative md:w-[9rem] md:ml-3 md:px-2 md:text-center md:text-white md:font-bold md:text-xl"
              style={{ background: "#C8DF4B" }}
            >
              {buyBack}
            </span>
            <span className="md:text-lg md:font-bold md:mx-3 md:text-left md:mb-3 md:my-2 md:text-white">
              {buyBackDesc}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
